import Page from '../components/page'
import React from 'react'
import Head from 'next/head'

import {
  visitors2024,
  visitors2023,
  visitors2022,
  visitors2021
} from '../visitors'
import { VisitorHorz } from '../components/visitor'

export default function Home() {
  return (
    <>
      <Head>
        <title>VAST: Visiting Artist & Scholar Talks</title>
      </Head>
      <Page>
        <h3 className="font-roboto-condensed text-2xl font-bold text-darkgray">
          2024-25
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 mb-8">
          {Object.values(visitors2024).map((v) => (
            <VisitorHorz key={v.slug} {...v} />
          ))}
        </div>


        <h3 className="font-roboto-condensed text-2xl font-bold text-darkgray">
          2023-24
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 mb-8">
          {Object.values(visitors2023).map((v) => (
            <VisitorHorz key={v.slug} {...v} />
          ))}
        </div>


        <h3 className="font-roboto-condensed text-2xl font-bold text-darkgray">
          2022-23
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 mb-8">
          {Object.values(visitors2022).map((v) => (
            <VisitorHorz key={v.slug} {...v} />
          ))}
        </div>

        <h3 className="font-roboto-condensed text-2xl font-bold text-darkgray">
          2021-22
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2 mb-8">
          {Object.values(visitors2021).map((v) => (
            <VisitorHorz key={v.slug} {...v} />
          ))}
        </div>
      </Page>
    </>
  )
}
